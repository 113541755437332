<template>
  <div class="popup_wrap" style="width:700px; height: 300px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont pop_hscd_area">
      <h1 class="page_title">{{ $t('msg.hsCdPop.001') }}</h1>

      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="10%">
            <col width="20%">
            <col width="60%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th>{{ $t('msg.hsCdPop.002') }}</th> <!-- HS CODE -->
              <th>{{ $t('msg.hsCdPop.003') }}</th> <!-- Name -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in hsCdList" :key="'trHsCd_' + idx">
              <td>{{ idx + 1 }}</td>
              <td>
                <input
                  v-model="vo.hsCd"
                  :id="'hsCd_' + idx"
                  maxlength="8"
                  type="text"
                  @keyup="$ekmtcCommon.onlyNumber($event)"
                  @input="fnIranCheck(idx)"
                >
              </td>
              <td></td>
              <td>
                <button
                  v-if="idx === 0"
                  type="button"
                  class="tbl_icon plus"
                  @click="addHsCd()"
                >
                  plus
                </button>
                <button
                  v-if="idx !== 0"
                  type="button"
                  class="tbl_icon minus"
                  @click="removeHsCd(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="text_center mt20">
        <a class="button lg blue font_16" @click="save()">{{ $t('msg.CSBK100.178') }}</a>
        <a class="button lg gray font_16" @click="$emit('close')">{{ $t('msg.CSBK100.179') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import transCommon from '@/api/rest/trans/transCommon'
export default {
  name: 'HsCodeIRPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      hsCdList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await transCommon.findIranHsCd(this.parentInfo.bkgNo).then(res => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          this.hsCdList = result
        }
      })

      if (this.hsCdList.length === 0) {
        this.addHsCd()
      }
    },
    addHsCd () {
      const hsCdList = this.hsCdList
      const seq = hsCdList.length === 0 ? 1 : Number(hsCdList[hsCdList.length - 1].seq) + 1
      const obj = {
        seq: seq,
        hsCd: ''
      }

      this.hsCdList.push(obj)
    },
    removeHsCd (idx) {
      const hsCdList = this.hsCdList

      hsCdList.splice(idx, 1)

      this.hsCdList.forEach((h, i) => {
        this.checkValidation(i)
      })
    },
    fnIranCheck (idx) {
      const isOk = this.checkValidation(idx)

      if (isOk) {
        return
      }

      const hsCdInfo = this.hsCdList[idx]
      const div = document.querySelector('.pop_hscd_area')
      const selector = div.querySelector('#hsCd_' + idx)
      const params = {
        kndCd: 3,
        hsCd: hsCdInfo.hsCd
      }

      transCommon.getIranCheck(params).then(res => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          if (result === 'Y') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.117'))
            hsCdInfo.succYn = 'N'
          }
        }
      })
    },
    checkValidation (idx) {
      const hsCdInfo = this.hsCdList[idx]
      const div = document.querySelector('.pop_hscd_area')
      const selector = div.querySelector('#hsCd_' + idx)

      let isOk = true
      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isNotEmpty(hsCdInfo.hsCd.trim())) {
        this.hsCdList.forEach((h, i) => {
          if (i !== idx && h.hsCd === hsCdInfo.hsCd) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.hsCdPop.005'))
            isOk = false
          }
        })
      } else {
        isOk = false
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
      }

      return isOk
    },
    save () {
      const bkgNo = this.parentInfo.bkgNo

      if (this.$ekmtcCommon.isEmpty(bkgNo)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.hsCdPop.004'))
        return
      }

      const hsCdList = this.hsCdList

      let isOk = true
      hsCdList.forEach((h, i) => {
        if (!this.checkValidation(i)) {
          isOk = false
        }

        if (h.succYn === 'N') {
          isOk = false
        }
      })

      if (isOk) {
        const regParams = {
          bkgNo: bkgNo,
          hsCdList: hsCdList
        }

        let succFlag = true
        transCommon.saveHsCdIranMulti(regParams).then(res => {
          if (res.headers.respcode !== 'C0000' || res.data === 'FAIL') {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag) {
            const TH = this
            this.$ekmtcCommon.alertCallback(this.$t('art.CMATK383'), () => {
              TH.$emit('close')
            })
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
          }
        })
      }
    }
  }
}
</script>
